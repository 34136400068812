import { Button, Typography } from '@maersktankersdigital/web-components'

import { Modal } from '~components/molecules/modal/modal'
import { ModalFooter } from '~components/molecules/modal/modal.styles'

interface Props {
  cancelButtonText?: string
  confirmButtonText?: string
  content: string
  header?: string
  isVisible: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const ConfirmModal = ({
  content,
  header = 'Are you sure?',
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  onConfirm,
  onClose,
  isVisible,
}: Props) => {
  const handleConfirm = () => {
    onConfirm?.()
    onClose?.()
  }

  return (
    <Modal
      variant="confirm"
      open={isVisible}
      onClose={onClose}
      title={header}
      disableCloseIcon
    >
      <>
        <Typography variant="paragraph2">{content}</Typography>
        <ModalFooter>
          <Button onClick={onClose} variant="secondary">
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm}>{confirmButtonText}</Button>
        </ModalFooter>
      </>
    </Modal>
  )
}

export default ConfirmModal
