import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface GetVesselsResItem {
  imoNumber: string
  pool: string
  poolPartner: string
  targetDate: Date
  status: ReStatus
  vesselName: string
  progress: number
  tcin: Tcin[]
  currentVoyage: CurrentVoyage
  vesselId: string
  vesselCode: string
  poolPartnerName?: string
  deliveryDate: Date
  redeliveryDate: Date
  onboardedDate?: Date
  archivedAt?: Date
  archivedReason?: string
}

interface CurrentVoyage {
  status?: CurrentVoyageStatus
  chartererShortName?: string
  department?: string
  ballastPort?: string
  loadPort?: string
  dischargePort?: string
}

export enum CurrentVoyageStatus {
  Commenced = 'Commenced',
  Scheduled = 'Scheduled',
}

export enum ReStatus {
  CommercialManagement = 'commercial-management',
  Onboarded = 'onboarded',
  Onboarding = 'onboarding',
}

interface Tcin {
  deliveryDateLocal: Date
  redeliveryDateLocal: Date
  counterpartyShortName: string
  status: TcinStatus
  vesselCode: string
}

export enum TcinStatus {
  Delivered = 'Delivered',
  Redelivered = 'Redelivered',
}

export function useGetVessels(poolName?: string) {
  const fetchRequest = useFetch<GetVesselsResItem[]>()
  const url = poolName ? `/vessels?pool=${poolName}` : '/vessels'

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: tenMinutes,
  })
}
