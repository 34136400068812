import { ApiRoutes } from '~api'

import { IVesselData } from '~types/vessel.types'
import { apiPostAction } from '../actions'
import { IVesselsErrorResponse } from './error'

export interface IVesselsAddVesselResponse extends IVesselData {
  createdAt?: string
  statusCode?: number
  vesselId?: string
}

export interface IVesselsAddVesselPayloadBody extends IVesselData {}

export interface IVesselsAddVesselPayload {
  _id?: string
  body: IVesselsAddVesselPayloadBody
}

export function vesselsAddAction(
  payload: IVesselsAddVesselPayload,
): Promise<IVesselsAddVesselResponse | IVesselsErrorResponse> {
  return apiPostAction<IVesselsAddVesselPayloadBody, IVesselsAddVesselResponse>(
    ApiRoutes.Vessels.add,
    payload.body,
  )
}
