import { useGetMeVT } from '../queries/me/use-get-me-vt'

export enum ExactScopeName {
  accountManager = '*:*:accountmanager',
  charter = 'vessel:*:chartering',
  emailFeatureFlag = 'feature-flag:-:email-subscription',
  maerskTankersUser = '*:*:mtuser',
  mtops = '*:*:mtops',
  mtopsVessel = 'vessel:*:mtops',
  poolPartner = 'Pool Partner',
  positionListAdmin = 'positionlist:admin',
  positionListUpdate = 'positionlist:update',
  superReader = '*:*:reader',
  superUser = '*:*:admin',
  technicalManager = 'Technical Manager',
  payout = 'feature-flag:payout',
}

export function useHasScope(scope: ExactScopeName) {
  const { data } = useGetMeVT()
  return data?.scope.some((item) => item === scope)
}
