import { FC, ReactElement } from 'react'

import {
  StyledFeedbackSideButton,
  StyledReportBugSideButton,
  StyledToTopSideButton,
} from './style'

export enum Types {
  reportBug = 'reportBug',
  toTop = 'toTop',
  feedback = 'feedback',
}

interface ISideButton {
  content: ReactElement | string
  onClick: () => void
  type: keyof typeof Types
}

const sideButtonTypes = {
  reportBug: StyledReportBugSideButton,
  toTop: StyledToTopSideButton,
  feedback: StyledFeedbackSideButton,
}

export const SideButton: FC<ISideButton> = ({ type, content, onClick }) => {
  const StyledSideButton = sideButtonTypes[type]

  return (
    <StyledSideButton type="button" onClick={onClick}>
      {content}
    </StyledSideButton>
  )
}
