import { apiGetAction } from '../actions'
import { ApiRoutes } from '../routes'

export interface IVesselsAllResponse {
  _id: string
  createdAt: string
}

export function vesselsAllAction(): Promise<IVesselsAllResponse> {
  return apiGetAction<IVesselsAllResponse>(ApiRoutes.Vessels.all)
}
