import { FC } from 'react'

export const FeedbackIframe: FC = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <iframe
        width="100%"
        height="1500px"
        src="https://forms.office.com/pages/responsepage.aspx?id=4gNdtZX9CkiRypzVj0LwfJjnZlkUu0pMmXbDk9lWLVVUOUhBOTVUNkZYREpVSDIzVVFVTDVPRUFSOSQlQCN0PWcu"
        frameBorder={0}
        marginWidth={0}
        marginHeight={0}
        style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
        allowFullScreen
      />
    </div>
  )
}
