import { FC, useState } from 'react'

import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { SideButton } from '~components/atoms/side-button/side-button'
import { Text } from '~components/atoms/text'

import useEventListener from '~hooks/use-event-listener'
import { PageRoute } from '../../../routes/routes-behind-login/vt-routing/constants/page-route'
import { StyledSideBarButtons } from './styles'

interface ISideBarButtons {
  isVisible: boolean
}
export const SideBarButtons: FC<ISideBarButtons> = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEventListener('scroll', window, () => setIsVisible(window.scrollY > 900))

  return (
    <StyledSideBarButtons>
      <div style={{ width: '40px', marginRight: '8px' }}>
        {isVisible ? (
          <SideButton
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
            type="toTop"
            content={
              <Icon name="arrowRightLong" fill="white" width={10} height={10} />
            }
          />
        ) : null}
      </div>
      <div style={{ marginRight: '8px' }}>
        <SideButton
          onClick={() =>
            window.open(window.location.origin + PageRoute.FEEDBACK, '_blank')
          }
          type="feedback"
          content={
            <Box flexDirection="row" alignItems="center">
              <div
                style={{
                  transform: 'rotate(90deg)',
                  marginRight: '8px',
                  marginLeft: '-12px',
                }}
              >
                <Icon name="iconComments" fill="white" width={32} height={32} />
              </div>
              <Text text="Feedback" color="white" size="small" />
            </Box>
          }
        />
      </div>
      <div>
        <SideButton
          onClick={() =>
            window.open(window.location.origin + PageRoute.REPORT_BUG, '_blank')
          }
          type="reportBug"
          content="Report a bug"
        />
      </div>
    </StyledSideBarButtons>
  )
}
