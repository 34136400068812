import { Label, SelectProps } from '@maersktankersdigital/web-components'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { StyledSelect } from '~components/molecules/modal/modal.styles'
import { IOption } from '~types/components'
import { Z_INDEX } from '~types/z-index'
import { assembleValidations } from '~utils/form-validations'

export enum Variants {
  basic = 'basic',
  filters = 'filters',
}

interface ISelectField {
  defaultValue?: string
  isDisabled?: boolean
  label: string
  name: string
  options: IOption[]
  placeholder?: string
  required?: boolean
  validations?: RegisterOptions
}

type SelectFieldProps = ISelectField & SelectProps

export const SelectField = ({
  name,
  validations,
  placeholder,
  required,
  label,
  onChange,
  options,
  defaultValue,
  isDisabled,
  className,
}: SelectFieldProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()
  const handleChange = (
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element, Element>
      | null,
    value: string,
    name: string,
  ) => {
    if (value === null) return
    onChange?.(event, value, name)
    setValue(name, value)
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={assembleValidations(required, validations)}
      render={({ field: { value, ref } }) => {
        return (
          <>
            {label && <Label id={name} label={label} required={required} />}
            <StyledSelect
              name={name}
              onChange={handleChange}
              options={options}
              placeholder={placeholder}
              className={className}
              ref={ref}
              value={value}
              disabled={isDisabled}
              error={
                errors[name] ? (errors[name]?.message as string) : undefined
              }
              slotProps={{ popper: { style: { zIndex: Z_INDEX.Popup } } }}
            />
          </>
        )
      }}
    />
  )
}
