import { FC } from 'react'

export const ReportBugIframe: FC = () => (
  <div style={{ width: '100vw' }}>
    <iframe
      width="100%"
      height="4000px"
      src="https://forms.office.com/pages/responsepage.aspx?id=4gNdtZX9CkiRypzVj0LwfJjnZlkUu0pMmXbDk9lWLVVUNjRSNEFWTUxaMTM3Vk1aNkgwRUtNNUo4UiQlQCN0PWcu"
      frameBorder={0}
      marginWidth={0}
      marginHeight={0}
      style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
      allowFullScreen
    />
  </div>
)
