import { AutoCompleteInputOptions } from '~components/molecules/auto-complete-input/auto-complete.types'
import {
  FormAutoCompleteConfig,
  FormField,
} from '~components/organisms/form/form.types'

export enum FORM_API_DATA_KEY {
  POOL_PARTNERS = 'poolPartners',
  VOYAGE_MANAGERS = 'voyageManagers',
  PARTNER_MANAGERS = 'partnerManagers',
  Q88_VESSELS = 'q88Vessels',
  POOL_POINT_VERSIONS = 'poolPointsVersions',
}

export function getFormAutoCompleteOptions(
  apiData: any[],
  field: FormField & FormAutoCompleteConfig,
) {
  return apiData?.reduce(
    (prevVal: AutoCompleteInputOptions[], apiData: any) => {
      const existingDataKeys = field.apiConfig?.optionKeys.filter(
        (key) => apiData[key],
      )

      if (existingDataKeys?.length === 0) return prevVal

      return [
        ...prevVal,
        {
          searchableItems: existingDataKeys!.map((key, index) => ({
            key,
            value: apiData[key],
            color: field.listColors?.[index] ?? 'inherit',
          })),
        },
      ]
    },
    [],
  )
}
