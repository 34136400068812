import styled from 'styled-components/macro'

import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledSideButton = styled.button`
  color: ${Colors.white};
  font-size: ${Fonts.sizes.small};
  font-family: ${Fonts.families.regular};
  outline: none;
  &:focus {
    outline: none;
  }
`
export const StyledReportBugSideButton = styled(StyledSideButton)`
  background: ${Colors.oranges.brick};
  border-radius: 2px 2px 0 0;
  padding: 8px 14px;
  text-align: center;
  height: 44px;
  border: none;
`
export const StyledFeedbackSideButton = styled(StyledSideButton)`
  background: #4db5ab;
  border-radius: 2px 2px 0 0;
  padding: 4px 14px;
  height: 44px;
  border: none;
`
export const StyledToTopSideButton = styled(StyledSideButton)`
  background: ${Colors.blues.maersk};
  border-radius: 2px 2px 0 0;
  padding: 15px 14px 15px 16px;
  height: 44px;
  border: none;
`
