import { Alert } from '@maersktankersdigital/web-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ApiRoutes } from '~api'
import { IVesselsReadResponse } from '~api/vessels/read'
import LoadComponent from '~components/molecules/load-component/load-component'
import { ITabsReadResponse } from '~hooks/queries/vessels/data/types/tabs-read'
import { useApi } from '~hooks/use-api'
import { StyledVesselPage } from '~pages/pages-behind-login/vessel-page/vessel-page.styles'
import { VesselPageContent } from '~pages/pages-behind-login/vessel-page/vessel-page-content/vessel-page-content'
import { PageRoute } from '~routing/constants/page-route'
import { VESSEL_PAGE_ROUTES } from '~routing/constants/routes'
import { getFilteredVesselPageRoutes } from '~routing/utils'
import { getActiveRoute } from '~utils/get-active-route'

export default function VesselPage() {
  const { vesselId } = useParams()

  const {
    data,
    error: hasVesselError,
    isLoading: isVesselLoading,
  } = useApi<IVesselsReadResponse>(ApiRoutes.Vessels.read, {
    vesselId,
  })

  const {
    data: tabPermissions,
    isLoading: isPermissionsLoading,
    error: hasPermissionsError,
  } = useApi<ITabsReadResponse[]>(ApiRoutes.Vessels.Tabs.all, {
    vesselId,
  })
  const isLoading = isVesselLoading || isPermissionsLoading
  const error = hasVesselError || hasPermissionsError

  const routeParams = useParams()
  const { pathname } = useLocation()
  const [, activeRoute] = getActiveRoute(pathname, routeParams)
  const navigate = useNavigate()

  if (isLoading) return <LoadComponent />

  if (!data || error) {
    return (
      <Alert
        variant="error"
        text="Oops! There was an error loading the vessel. Please reload the page or try again later."
      />
    )
  }

  if (!vesselId) {
    return (
      <Alert
        variant="error"
        text="Oops! It looks like no data exists for this vessel. Please try a different vessel or try again later."
      />
    )
  }

  const pageConfigs = getFilteredVesselPageRoutes(
    VESSEL_PAGE_ROUTES,
    tabPermissions!,
  )
  const allowedRoutes = Object.keys(pageConfigs || {})

  if (activeRoute && !allowedRoutes.includes(activeRoute)) {
    navigate(PageRoute.UNAUTHORIZED)
    return null
  }

  return (
    <StyledVesselPage>
      {/* @ts-ignore */}
      <VesselPageContent data={data} pageConfigs={pageConfigs} />
    </StyledVesselPage>
  )
}
